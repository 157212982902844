/**
 * IMPORTS
 */
import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import Header from './components/Header';
import Home from './components/Home';
import Login from './components/Login';

/**
 * CORE
 */

const App = () => (
  <>
    <Route component={Header} />
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route render={(props) => <Home {...props} />} />
    </Switch>
  </>
);

export default App;
