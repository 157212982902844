/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import imgAdd from '../assets/images/add.png';

/**
 * CORE
 */

const PickableImage = ({ src, isSelected, onClick }) => (
  <div
    className={isSelected ? 'pickable-image pickable-image-selected' : 'pickable-image'}
    onClick={onClick}
  >
    <img
      src={src}
      alt="library-asset"
    />
  </div>
);

PickableImage.propTypes = {
  src: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

PickableImage.defaultProps = {
  isSelected: false,
  onClick: undefined,
};

export default class ImagePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      picked: null,
    };

    this.fileInputRef = React.createRef();
    this.onFilePicked = this.onFilePicked.bind(this);
    this.onExtraClick = this.onExtraClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.images.length < this.props.images.length) {
      const newImage = [...this.props.images].pop();
      this.onValueChange(newImage.value);
    }
  }

  onFilePicked(e) {
    if (this.props.onExtraFile) {
      this.props.onExtraFile(e.target.files[0]);
    }
  }

  onExtraClick() {
    this.fileInputRef.current.click();
  }

  onValueChange(value) {
    this.setState({ picked: value });
    if (this.props.onPick) {
      this.props.onPick(value);
    }
  }

  render() {
    const { images, allowExtra } = this.props;
    const { picked } = this.state;
    return (
      <div className="image-picker">
        {images.map((image) => (
          <PickableImage
            src={image.src}
            isSelected={image.value === picked}
            onClick={() => this.onValueChange(image.value)}
            key={image.value}
          />
        ))}
        {allowExtra && (
          <>
            <input
              ref={this.fileInputRef}
              type="file"
              hidden
              onChange={this.onFilePicked}
              accept=".png,.jpg,.jpeg"
            />
            <div
              className="pickable-image-add"
              onClick={this.onExtraClick}
            >
              <img
                src={imgAdd}
                alt="library-asset"
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

ImagePicker.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onPick: PropTypes.func,
  allowExtra: PropTypes.bool,
  onExtraFile: PropTypes.func,
};

ImagePicker.defaultProps = {
  onPick: undefined,
  onExtraFile: undefined,
  allowExtra: false,
};
