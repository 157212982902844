/**
 * IMPORTS
 */

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Alert,
  Button,
  Form,
  FormGroup,
  Label,
  // CustomInput,
} from 'reactstrap';
import {
  withRouter,
  Link,
} from 'react-router-dom';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import thumbs from '../assets/thumbs';
import {
  listProjects,
  setCurrentProject,
  createJob,
  uploadFile,
} from '../actions';

import {
  shouldRefreshProjects,
  getCurrentProject,
} from '../selectors/projects-selectors';

import {
  isUploading,
  isRequesting,
} from '../selectors/app-selectors';
import ImagePicker from './ImagePicker';

/**
 * CORE
 */

const UploadAlert = () => (
  <Row>
    <Col>
      <Alert
        color="primary"
      >
        <span>
          <FA
            icon={faSpinner}
            pulse
          />
          <span>
            {' Uploading files'}
          </span>
        </span>
      </Alert>
    </Col>
  </Row>
);

class Project extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.shouldRefresh) {
      this.props.listProjects();
    }
    this.props.setCurrentProject(this.props.projectId);
  }

  onSubmit(e) {
    e.preventDefault();
    const { values } = this.state;
    const { project } = this.props;
    this.props.createJob(project.id, values);
  }

  renderInputs() {
    const { project } = this.props;
    if (!project) return null;
    const { inputs } = project;
    const { values } = this.state;
    return (
      <>
        {Object.keys(inputs).map((k) => {
          const {
            label = 'Image',
            library = [],
            uploads = [],
            allowExtra = false,
          } = inputs[k];
          return (
            <FormGroup key={k}>
              <Label>{label}</Label>
              <ImagePicker
                images={[
                  ...library.map((id) => ({ src: thumbs[id], value: `library://${id}` })),
                  ...uploads.map((src) => ({ src, value: src })),
                ]}
                onPick={(v) => this.setState({ values: { ...values, [k]: v } })}
                onExtraFile={(file) => this.props.uploadFile(project.id, k, file)}
                allowExtra={allowExtra}
              />
            </FormGroup>
          );
        })}
      </>
    );
  }

  render() {
    const {
      project,
      uploading,
      requesting,
    } = this.props;
    if (project === null) {
      return (
        <Row>
          <Col>
            <h4>Project is loading...</h4>
          </Col>
        </Row>
      );
    }

    const {
      id,
      name,
      inputs,
    } = project;
    const { values } = this.state;
    return (
      <>
        <Row className="project-form">
          <Col>
            <Row>
              <Col>
                <h6>
                  <Link to="/">
                    Demos
                  </Link>
                  {` > ${id}`}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h2>
                  {name}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form onSubmit={this.onSubmit}>
                  {this.renderInputs()}
                  <Button
                    type="submit"
                    color="primary"
                    disabled={requesting || Object.keys(values).length < Object.keys(inputs).length}
                  >
                    {requesting ? <FA icon={faSpinner} spin /> : 'Submit'}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        {uploading && <UploadAlert />}
      </>
    );
  }
}

Project.propTypes = {
  uploading: PropTypes.bool.isRequired,
  requesting: PropTypes.bool.isRequired,
  shouldRefresh: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    inputs: PropTypes.objectOf(PropTypes.shape({
      label: PropTypes.string,
      library: PropTypes.arrayOf(PropTypes.string),
      uploads: PropTypes.arrayOf(PropTypes.string),
      allowExtra: PropTypes.bool,
    })).isRequired,
  }),
  listProjects: PropTypes.func.isRequired,
  setCurrentProject: PropTypes.func.isRequired,
  createJob: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
};

Project.defaultProps = {
  project: null,
};

export default compose(
  withRouter,
  connect(
    (
      state,
      { match: { params: { id } } },
    ) => ({
      shouldRefresh: shouldRefreshProjects(state),
      projectId: id,
      project: getCurrentProject(state),
      uploading: isUploading(state),
      requesting: isRequesting(state),
    }),
    {
      listProjects,
      setCurrentProject,
      createJob,
      uploadFile,
    },
  ),
)(Project);
