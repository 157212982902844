/**
 * CORE
 */

const initial = {
  uploads: 0,
  requests: 0,
};

export default (state = initial, action) => {
  switch (action.type) {
    case 'CREATE_JOB_REQUEST':
      return {
        ...state,
        requests: state.requests + 1,
      };

    case 'CREATE_JOB_FAILURE':
    case 'CREATE_JOB_SUCCESS':
      return {
        ...state,
        requests: state.requests - 1,
      };

    case 'UPLOAD_FILE_REQUEST':
      return {
        ...state,
        uploads: state.uploads + 1,
      };

    case 'UPLOAD_FILE_SUCCESS':
    case 'UPLOAD_FILE_FAILURE':
      return {
        ...state,
        uploads: state.uploads - 1,
      };

    default:
      return state;
  }
};
