/* eslint-disable import/prefer-default-export */
/**
 * IMPORTS
 */

import { createSelector } from 'reselect';

/**
 * CORE
 */

const getAppEntity = ({ app }) => app;

export const isUploading = createSelector(
  getAppEntity,
  (app) => app.uploads > 0,
);

export const isRequesting = createSelector(
  getAppEntity,
  (app) => app.requests > 0,
);
