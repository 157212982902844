/**
 * IMPORTS
 */

import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import projects from './projects';
import jobs from './jobs';
import app from './app';
import auth from './auth';

/**
 * CORE
 */

export default combineReducers({
  projects,
  jobs,
  app,
  auth,
  form,
});
