/**
 * IMPORTS
 */

import { createSelector } from 'reselect';

/**
 * CORE
 */

const getAuthEntity = ({ auth }) => auth;

export const getUsername = createSelector(
  getAuthEntity,
  (auth) => auth.username,
);

export const isAuthenticated = createSelector(
  getAuthEntity,
  (auth) => auth.authenticated,
);

export const needsRedirectToLogin = createSelector(
  getAuthEntity,
  (auth) => auth.needsRedirectToLogin,
);

export const hasWrongCredentials = createSelector(
  getAuthEntity,
  (auth) => auth.wrongCredentials,
);
