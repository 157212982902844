/**
 * IMPORTS
 */

import { createSelector } from 'reselect';
import _ from 'lodash';

/**
 * CORE
 */

const getProjectsEntity = ({ projects }) => projects;

export const shouldRefreshProjects = createSelector(
  getProjectsEntity,
  (projects) => !projects.fetched && !projects.fetching,
);

export const getProjectsIds = createSelector(
  getProjectsEntity,
  (projects) => projects.ids,
);

export const getProjects = createSelector(
  getProjectsEntity,
  getProjectsIds,
  (projects, ids) => ids.map((id) => projects[id]),
);

export const getCurrentProjectId = createSelector(
  getProjectsEntity,
  (projects) => projects.current,
);

export const getCurrentProject = createSelector(
  getProjectsEntity,
  getCurrentProjectId,
  (projects, projectId) => _.get(projects, projectId),
);
