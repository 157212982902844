/**
 * IMPORTS
 */

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactInterval from 'react-interval-timer';
import {
  Row,
  Col,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';

import Job from './Job';

import {
  refreshJobs,
  clearAllJobs,
} from '../actions';

import {
  getJobs,
  getPendingJobs,
  getCompletedJobs,
} from '../selectors/jobs-selectors';
import { getProjects } from '../selectors/projects-selectors';

/**
 * CONSTANTS
 */

const {
  REACT_APP_REFRESH_INTERVAL: REFRESH_INTERVAL,
} = process.env;

/**
 * CORE
 */

const Status = ({ jobs, pending, completed, projects, ...props }) => (
  <div id="status">
    <ReactInterval
      timeout={REFRESH_INTERVAL * 1000}
      callback={props.refreshJobs}
      enabled={!!(pending && pending.length)}
      repeat
    />
    <Row>
      <Col>
        <h2 className="title">
          Tasks
          {!!jobs.length && (
            <Button
              size="sm"
              outline
              onClick={props.clearAllJobs}
              style={{ float: 'right' }}
              disabled={!completed || completed.length === 0}
            >
              Clear
            </Button>
          )}
        </h2>
      </Col>
    </Row>
    <Row>
      <Col>
        {jobs.length === 0 && 'No tasks (yet)'}
        {jobs.length > 0 && jobs.map((job) => (
          <Row key={job.id} className="mb-2">
            <Col>
              <Job
                {...job}
                duration={projects.find(({ id }) => id === job.projectId).duration}
              />
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  </div>
);

Status.propTypes = {
  jobs: PropTypes.array.isRequired,
  pending: PropTypes.array.isRequired,
  completed: PropTypes.array.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({
    duration: PropTypes.number.isRequired,
  })).isRequired,
  refreshJobs: PropTypes.func.isRequired,
  clearAllJobs: PropTypes.func.isRequired,
};

export default compose(
  connect(
    (state) => ({
      jobs: getJobs(state),
      pending: getPendingJobs(state),
      completed: getCompletedJobs(state),
      projects: getProjects(state),
    }),
    {
      refreshJobs,
      clearAllJobs,
    },
  ),
)(Status);
