/**
 * IMPORTS
 */

import { createSelector } from 'reselect';

/**
 * CORE
 */

const getJobsEntity = ({ jobs }) => jobs;

export const getJobsIds = createSelector(
  getJobsEntity,
  (jobs) => jobs.ids,
);

export const getJobs = createSelector(
  getJobsEntity,
  getJobsIds,
  (jobs, ids) => ids.map((id) => jobs[id]),
);

export const getCompletedJobIds = createSelector(
  getJobsEntity,
  (jobs) => jobs.completed,
);

export const getCompletedJobs = createSelector(
  getJobsEntity,
  getCompletedJobIds,
  (jobs, ids) => ids.map((id) => jobs[id]),
);

export const getPendingJobsIds = createSelector(
  getJobsEntity,
  (jobs) => jobs.pending,
);

export const getPendingJobs = createSelector(
  getJobsEntity,
  getPendingJobsIds,
  (jobs, ids) => ids.map((id) => jobs[id]),
);
