/**
 * IMPORTS
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  checkAuth,
} from '../actions';

import {
  isAuthenticated,
  needsRedirectToLogin,
} from '../selectors/auth-selectors';

import ProjectPicker from './ProjectPicker';
import Project from './Project';
import Status from './Status';
import Authenticating from './Authenticating';

/**
 * CORE
 */

class Home extends Component {
  componentDidMount() {
    this.props.checkAuth();
  }

  render() {
    const {
      authenticated,
      redirectToLogin,
    } = this.props;

    if (redirectToLogin) {
      return (
        <Redirect to="/login" />
      );
    }

    if (!authenticated) {
      return (
        <Authenticating />
      );
    }

    return (
      <Container
        fluid
        className="full-height-responsive"
        style={{ height: '100%' }}
      >
        <Row className="full-height-responsive" noGutters>
          <Col
            xs={{ size: 12, order: 2 }}
            lg={{ size: 8, order: 1 }}
            style={{
              paddingRight: '2rem',
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}
          >
            <Switch>
              <Route path="/" exact component={ProjectPicker} />
              <Route path="/:id" exact component={Project} />
            </Switch>
          </Col>
          <Col
            xs={{ size: 12, order: 1 }}
            lg={{ size: 4, order: 2 }}
            className="full-height-responsive"
          >
            <Row
              id="status-col"
              className="full-height-responsive"
              style={{
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              <Col>
                <Status />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

Home.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  redirectToLogin: PropTypes.bool.isRequired,
  checkAuth: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    authenticated: isAuthenticated(state),
    redirectToLogin: needsRedirectToLogin(state),
  }),
  {
    checkAuth,
  },
)(Home);
