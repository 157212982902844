/* global localStorage */

/**
 * IMPORTS
 */

import _ from 'lodash';

/**
 * CORE
 */

const load = () => {
  try {
    const serializedState = localStorage.getItem('redux-state');
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const save = (state) => {
  try {
    const serializedState = JSON.stringify(_.pick(state, 'jobs'));
    localStorage.setItem('redux-state', serializedState);
  } catch {
    // ignore write errors
  }
};

export default { save, load };
