/**
 * CORE
 */

const initial = {
  ids: [],
  fetched: false,
  fetching: false,
  current: null,
};

export default (state = initial, action) => {
  switch (action.type) {
    case 'LIST_PROJECTS_REQUEST':
      return {
        ...state,
        fetched: false,
        fetching: true,
      };

    case 'LIST_PROJECTS_SUCCESS':
      return {
        ...state,
        ids: action.projects.map(({ id }) => id),
        ...action.projects.reduce((acc, { id, ...rest }) => ({
          ...acc, [id]: { id, ...rest },
        }), {}),
        fetched: true,
        fetching: false,
      };

    case 'LIST_PROJECTS_FAILURE':
      return {
        ...state,
        fetched: false,
        fetching: false,
      };

    case 'SET_CURRENT_PROJECT':
      return {
        ...state,
        current: action.projectId,
      };

    case 'UPLOAD_FILE_SUCCESS':
      return {
        ...state,
        [action.projectId]: {
          ...(state[action.projectId] || {}),
          inputs: {
            ...(state[action.projectId].inputs || {}),
            [action.inputId]: {
              ...(state[action.projectId].inputs[action.inputId] || {}),
              uploads: [
                ...(state[action.projectId].inputs[action.inputId].uploads || []),
                action.url,
              ],
            },
          },
        },
      };

    default:
      return state;
  }
};
