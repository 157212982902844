/**
 * IMPORTS
 */

import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Progress,
} from 'reactstrap';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faFileVideo } from '@fortawesome/free-regular-svg-icons';
import ReactInterval from 'react-interval-timer';
import PropTypes from 'prop-types';

/**
 * CORE
 */

const DownloadButton = ({ url }) => (
  <a
    className="job-finish-label"
    href={url}
    rel="noopener noreferrer"
  >
    <Button color="primary" className="button-full-width">
      <FA icon={faFileVideo} />
      {' Download'}
    </Button>
  </a>
);

DownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
};

class TaskProgress extends Component {
  constructor(props) {
    super(props);
    this.state = { count: 0 };

    this.increment = this.increment.bind(this);
  }

  increment() {
    const { count } = this.state;
    this.setState({ count: count + 1 });
  }

  render() {
    const { count = 0 } = this.state;
    const { duration = 60 } = this.props;
    const percent = Math.max(0, Math.min(100, Math.round(100 * (count / duration))));
    return (
      <>
        <ReactInterval
          timeout={1000}
          callback={this.increment}
          enabled={count < duration}
          repeat
        />
        <Progress
          value={percent}
          animated
        />
      </>
    );
  }
}

TaskProgress.propTypes = {
  duration: PropTypes.number.isRequired,
};

const StatusLabel = ({ status }) => (
  <span className="job-pending-label">
    <span>{status.replace(/^./, status[0].toUpperCase())}</span>
  </span>
);

StatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
};

const Job = ({ projectId, status, duration, outputs }) => (
  <Card className="job">
    <CardBody>
      <Row>
        <Col xs={6} className="job-col">
          <small>
            {projectId}
          </small>
        </Col>
        <Col xs={6} className="job-col">
          {status === 'completed' && <DownloadButton url={outputs[0]} />}
          {status === 'processing' && <TaskProgress duration={duration} />}
          {status !== 'completed' && status !== 'processing' && <StatusLabel status={status} />}
        </Col>
      </Row>
    </CardBody>
  </Card>
);

Job.propTypes = {
  projectId: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  outputs: PropTypes.arrayOf(PropTypes.string),
};

Job.defaultProps = {
  outputs: [],
};

export default Job;
