/**
 * IMPORTS
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Navbar,
  NavbarBrand,
} from 'reactstrap';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { getUsername } from '../selectors/auth-selectors';

import logo from '../assets/images/PicsolveLab_white.png';

/**
 * CORE
 */

const Header = ({ username }) => (
  <Navbar expand="sm">
    <NavbarBrand href="/">
      <span>
        <img
          alt="logo"
          className="logo"
          src={logo}
          style={{ maxHeight: 35 }}
        />
        <span className="logo-title">
          {/* Video Engine */}
        </span>
      </span>
    </NavbarBrand>
    { username && (
      <>
        <span className="ml-auto">
          <FA icon={faUser} />
          &nbsp;
          {username}
          &nbsp;
          &nbsp;
          <a href="/login" className="btn btn-outline-light btn-sm">
            Logout
            &nbsp;
            <FA icon={faSignOutAlt} />
          </a>
        </span>
      </>
    )}
  </Navbar>
);

Header.propTypes = {
  username: PropTypes.string,
};

Header.defaultProps = {
  username: null,
};

export default connect(
  (state) => ({
    username: getUsername(state),
  }),
)(Header);
