/**
 * IMPORTS
 */

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import {
  reduxForm,
  Form,
  Field,
} from 'redux-form';
import {
  withRouter,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  login,
} from '../actions';

import {
  isAuthenticated,
  hasWrongCredentials,
} from '../selectors/auth-selectors';

/**
 * CORE
 */

const TextInput = ({ input }) => (
  <Input
    type="text"
    {...input}
  />
);

TextInput.propTypes = {
  input: PropTypes.object.isRequired,
};

const PasswordInput = ({ input }) => (
  <Input
    type="password"
    {...input}
  />
);

PasswordInput.propTypes = {
  input: PropTypes.object.isRequired,
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit({ username, password }) {
    this.props.login(username, password);
  }

  render() {
    const { handleSubmit, authenticated, wrongCredentials } = this.props;
    if (authenticated) {
      return (
        <Redirect to="/" />
      );
    }

    return (
      <Container fluid>
        <Row
          style={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          <Col xs={12} lg={6}>
            <Form onSubmit={handleSubmit(this.onSubmit)}>
              <h2>
                Login
              </h2>
              <FormGroup>
                <Label>
                  Username
                </Label>
                <Field
                  name="username"
                  component={TextInput}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Password
                </Label>
                <Field
                  name="password"
                  component={PasswordInput}
                />
              </FormGroup>
              <Button type="submit" color="primary">
                Submit
              </Button>
              {wrongCredentials && (
                <div className="text-danger" style={{ marginTop: '1rem' }}>
                  Login failed. Check credentials.
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  wrongCredentials: PropTypes.bool.isRequired,
  // Redux Form
  handleSubmit: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  connect(
    (state) => ({
      authenticated: isAuthenticated(state),
      wrongCredentials: hasWrongCredentials(state),
    }),
    {
      login,
    },
  ),
  reduxForm({
    form: 'auth',
    initialValues: {
      username: '',
      password: '',
    },
  }),
)(Login);
