/* global document */
/* eslint-disable react/jsx-filename-extension */

/**
 * IMPORTS
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import _ from 'lodash';

import App from './App';
import reducer from './reducers';
import rootSaga from './sagas';
import stateStorage from './utils/state-storage';
import './css/index.scss';

/**
 * CONSTANTS
 */

const {
  NODE_ENV,
} = process.env;

/**
 * CORE
 */

const isProd = NODE_ENV === 'production';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
// const initialState = stateStorage.load();
const store = createStore(
  reducer,
  // initialState,
  isProd
    ? applyMiddleware(...middlewares)
    : composeWithDevTools(applyMiddleware(...middlewares)),
);

store.subscribe(
  _.throttle(() => stateStorage.save(store.getState())),
  1000,
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root'),
);
