/**
 * UTILS
 */

function getJobCurrentStatus({ history }) {
  const last = [...history].pop();
  if (last === undefined) return 'initialized';
  const { event } = last;
  if (event === 'start') return 'processing';
  if (event === 'complete') return 'completed';
  if (event === 'abandon') return 'abandonned';
  return 'error';
}

function isJobComplete(status) {
  return getJobCurrentStatus(status) === 'completed';
}

/**
 * CORE
 */

const initial = {
  ids: [],
  pending: [],
  completed: [],
};

export default (state = initial, action) => {
  switch (action.type) {
    case 'CREATE_JOB_SUCCESS':
      return {
        ...state,
        ids: [...state.ids, action.jobId],
        pending: [...state.pending, action.jobId],
        [action.jobId]: {
          id: action.jobId,
          projectId: action.projectId,
          status: 'initialized',
          outputs: [],
        },
      };

    case 'GET_JOB_STATUS_SUCCESS':
      return {
        ...state,
        pending: isJobComplete(action.status)
          ? state.pending.filter((id) => id !== action.jobId)
          : [...state.pending],
        completed: isJobComplete(action.status)
          ? [...state.completed, action.jobId]
          : [...state.completed],
        [action.jobId]: {
          ...state[action.jobId],
          status: getJobCurrentStatus(action.status),
          outputs: action.status.outputs,
        },
      };

    case 'CLEAR_ALL_JOBS': {
      return {
        ids: state.ids.filter((id) => state.pending.includes(id)),
        completed: [],
        pending: [...state.pending],
        ...(
          state.pending
            .map((id) => ({ [id]: state[id] }))
            .reduce((acc, c) => ({ ...acc, ...c }), {})
        ),
      };
    }

    default:
      return state;
  }
};
