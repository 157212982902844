/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import {
  Link,
} from 'react-router-dom';

import {
  listProjects,
} from '../actions';

import {
  getProjects,
} from '../selectors/projects-selectors';

/**
 * CORE
 */

class ProjectPicker extends Component {
  componentDidMount() {
    const { projects } = this.props;
    if (!projects.length) {
      this.props.listProjects();
    }
  }

  render() {
    const { projects } = this.props;
    return (
      <>
        <Row>
          <Col>
            <h2>Demos</h2>
          </Col>
        </Row>
        <Row>
          {projects.map(({ id, name }) => (
            <Col
              key={id}
              xs={12}
              lg={6}
            >
              <Link
                to={`/${id}`}
                className="project-card"
              >
                <Card>
                  <CardBody>
                    <small>
                      {id}
                    </small>
                    <h4>
                      {name}
                    </h4>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </>
    );
  }
}

ProjectPicker.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  listProjects: PropTypes.func.isRequired,
};

ProjectPicker.defaultProps = {
  projects: null,
};

export default connect(
  (state) => ({
    projects: getProjects(state),
  }),
  {
    listProjects,
  },
)(ProjectPicker);
