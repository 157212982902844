/**
 * CORE
 */

const initial = {
  username: null,
  authenticated: false,
  needsRedirectToLogin: false,
  wrongCredentials: false,
};

export default (state = initial, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
    case 'CHECK_AUTH_REQUEST': {
      return {
        ...state,
        username: null,
        needsRedirectToLogin: false,
        authenticated: false,
      };
    }

    case 'LOGIN_SUCCESS':
    case 'CHECK_AUTH_SUCCESS': {
      return {
        ...state,
        username: action.username,
        wrongCredentials: false,
        authenticated: true,
      };
    }

    case 'LOGIN_FAILURE':
      return {
        ...state,
        username: null,
        wrongCredentials: true,
        authenticated: false,
        needsRedirectToLogin: true,
      };

    case 'CHECK_AUTH_FAILURE': {
      return {
        ...state,
        username: null,
        authenticated: false,
        needsRedirectToLogin: true,
      };
    }

    default:
      return state;
  }
};
