import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

const Authenticating = () => (
  <Container fluid>
    <Row
      style={{
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >
      <Col xs={12} lg={6}>
        <h4>Checking authentication...</h4>
      </Col>
    </Row>
  </Container>
);

export default Authenticating;
