/**
 * CORE
 */

export const listProjects = () => ({
  type: 'LIST_PROJECTS_REQUEST',
});

export const setCurrentProject = (projectId) => ({
  type: 'SET_CURRENT_PROJECT',
  projectId,
});

export const uploadFile = (projectId, inputId, file) => ({
  type: 'UPLOAD_FILE_REQUEST',
  projectId,
  inputId,
  file,
});

export const createJob = (projectId, values) => ({
  type: 'CREATE_JOB_REQUEST',
  projectId,
  values,
});

export const refreshJobs = () => ({
  type: 'REFRESH_JOBS_REQUEST',
});

export const getJobStatus = (projectId, jobId) => ({
  type: 'GET_JOB_STATUS_REQUEST',
  projectId,
  jobId,
});

export const clearAllJobs = () => ({
  type: 'CLEAR_ALL_JOBS',
});

export const login = (username, password, loginCB) => ({
  type: 'LOGIN_REQUEST',
  username,
  password,
  loginCB,
});

export const checkAuth = () => ({
  type: 'CHECK_AUTH_REQUEST',
});
