/**
 * IMPORTS
 */

import {
  takeEvery,
  put,
  select,
  all,
  call,
} from 'redux-saga/effects';
import axios from 'axios';
import { encode } from 'querystring';

import {
  getJobStatus,
} from '../actions';

import {
  getPendingJobs,
} from '../selectors/jobs-selectors';

import {
  isAuthenticated,
  getUsername,
} from '../selectors/auth-selectors';

/**
 * CORE
 */

const api = axios.create({ baseURL: '/api' });

async function uploadFile(projectId, file) {
  const { type } = file;

  // get signed post url
  const { data: { data: { upload, download } } } = await api.get(`/upload?${encode({ projectId, type })}`);

  // upload file to s3 using presigned post url
  await axios.put(upload, file, { headers: { 'Content-type': type } });

  return download;
}

function* onListProjectsRequest() {
  try {
    const { data: { data: projects } } = yield api.get('/projects');
    yield put({
      type: 'LIST_PROJECTS_SUCCESS',
      projects,
    });
  } catch (error) {
    yield put({
      type: 'LIST_PROJECTS_FAILURE',
      error,
    });
  }
}

function* onUploadRequest({ projectId, inputId, file }) {
  try {
    const url = yield call(uploadFile, projectId, file);

    yield put({
      type: 'UPLOAD_FILE_SUCCESS',
      projectId,
      inputId,
      url,
    });
  } catch (error) {
    yield put({
      type: 'UPLOAD_FILE_FAILURE',
      error: error.message || String(error),
    });
  }
}

function* onCreateJobRequest({ projectId, values }) {
  try {
    const { data } = yield api.post(`/send?${encode({ projectId })}`, values);
    yield put({
      type: 'CREATE_JOB_SUCCESS',
      projectId,
      jobId: data.jobId,
    });
  } catch (error) {
    yield put({
      type: 'CREATE_JOB_FAILURE',
      error,
    });
  }
}

function* onRefreshJobs() {
  const pendingJobs = yield select(getPendingJobs);
  yield all(pendingJobs.map(({ projectId, id: jobId }) => put(getJobStatus(projectId, jobId))));
}

function* onGetJobStatusRequest({ projectId, jobId }) {
  try {
    const { data: { data: status } } = yield api.get(`/job?${encode({ jobId })}`);
    yield put({
      type: 'GET_JOB_STATUS_SUCCESS',
      projectId,
      jobId,
      status,
    });
  } catch (error) {
    yield put({
      type: 'GET_JOB_STATUS_FAILURE',
      error,
    });
  }
}

function* onLoginRequest({ username, password }) {
  try {
    yield api.post('/login', { username, password });
    yield put({
      type: 'LOGIN_SUCCESS',
      username,
    });
  } catch (error) {
    yield put({
      type: 'LOGIN_FAILURE',
      error,
    });
  }
}

function* onCheckAuthRequest() {
  try {
    const authenticated = yield select(isAuthenticated);
    let username = yield select(getUsername);
    if (!authenticated) {
      const { data: { data } } = yield axios.get('/api/check-login');
      username = data.username;
    }
    yield put({
      type: 'CHECK_AUTH_SUCCESS',
      username,
    });
  } catch (error) {
    yield put({
      type: 'CHECK_AUTH_FAILURE',
      error,
    });
  }
}

export default [
  takeEvery('LIST_PROJECTS_REQUEST', onListProjectsRequest),
  takeEvery('UPLOAD_FILE_REQUEST', onUploadRequest),
  takeEvery('CREATE_JOB_REQUEST', onCreateJobRequest),
  takeEvery('REFRESH_JOBS_REQUEST', onRefreshJobs),
  takeEvery('GET_JOB_STATUS_REQUEST', onGetJobStatusRequest),
  takeEvery('LOGIN_REQUEST', onLoginRequest),
  takeEvery('CHECK_AUTH_REQUEST', onCheckAuthRequest),
];
